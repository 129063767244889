<template src="./Template.vue"></template>
<style src="./Catalogo.scss" lang="scss" scoped ></style>

<script>
import Widget from '@/components/Widget/Widget';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'Catalogo',
  components: { Widget, Loading },
  data() {
    return {
      imput_marca   : '',
      imput_modelo  : '',
      imput_detalle : '',
      tr_marca      : null,
      tr_modelo     : null,
      modal_catalogo: false,
      item: {
        marca_id: 0,
        modelo_id: 0,
        detalle_id: 0
      },
      editar_marca        : false,
      editar_marca_index  : -1,
      editar_modelo       : false,
      editar_modelo_index : -1,
      editor_marca  : {
        id      : 0,
        nombre  : ''
      },
      editor_modelo  : {
        id      : 0,
        id_marca: 0,
        nombre  : ''
      },
      modal : { 
        id_detalle    : 0,
        marca_id      : 0, 
        marca         : '',
        modelo_id     : 0, 
        detalle       : '',
        modelo        : '', 
        ano           : '', 
        f_aire        : '', 
        f_aceie       : '', 
        f_combustible : '', 
        bujia         : '', 
        otro          : '',  }
      ,
      array_marca   : [],
      array_modelo  : [],
      array_detalle : [],
      array_arreglo : [],
      Load_marca    : false, 
      Load_modelo   : false, 
      Load_detalle  : false, 
      NUM_RESULTS2     : 70, // cantidad de resultados por página
      pag2             : 1, // Página inicial
      NUM_RESULTS3     : 70, // cantidad de resultados por página
      pag3             : 1, // Página inicial
    };
  },
  methods: {
    async Traer_marca(){
      try {
        this.Load_marca = true;
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Catalogo/TraerMarca?token=${token}`);
        this.array_marca = JSON.parse(result);
      } catch (error) {
        //error
      } finally {
        this.Load_marca = false;
      }
    },
    async Traer_modelo(){
      try {
        this.Load_modelo = true;
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Catalogo/TraerModelo?token=${token}`);
        this.array_modelo = JSON.parse(result);
      } catch (error) {
        //error
      }finally {
        this.Load_modelo = false;
      }
    },
    async Traer_detalle(){
      try {
        this.Load_detalle = true;
        let token = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Catalogo/TraerDetalle?token=${token}`);
        this.array_detalle = JSON.parse(result);
      } catch (error) {
        //error
      }finally {
        this.Load_detalle = false;
      }
    },
    filtrarProductosPorTipo(tipo, valor) {
      try {
        this.Button_Todo();
        let arreglo = [];
        let arreglo2 = [];
        let unicos = [];
        let filtros = this.array_detalle.filter(detalle => detalle[tipo] == valor);

        for (let i = 0; i < filtros.length; i++) {
          arreglo.push(filtros[i].id_modelo);
        }
        for (let e = 0; e < arreglo.length; e++) {
          const elemento = arreglo[e];
          if (!unicos.includes(elemento)) {
            unicos.push(elemento);
          }
        }

        for (let modelo of this.array_modelo) {
          for (let uni of unicos) {
            if (modelo.id == uni) {
              arreglo2.push({
                id: modelo.id,
                id_marca: modelo.id_marca,
                nombre: modelo.nombre
              });
            }
          }
        }

        if (arreglo2.length > 0) {
          this.array_arreglo = arreglo2;
        }

      } catch (error) {
        console.log(error);
      }
    },
    filtrar_Faire(valor) {
      this.filtrarProductosPorTipo('filtro_aire', valor);
    },
    filtrar_Faceite(valor) {
      this.filtrarProductosPorTipo('filtro_aceite', valor);
    },
    filtrar_Fcombustible(valor) {
      this.filtrarProductosPorTipo('filtro_combustible', valor);
    },
    Button_Todo(){
      this.item.marca_id = 0;
      this.item.modelo_id = 0;
      this.imput_marca = ''; 
      this.imput_modelo = '';
      this.tr_modelo = null; 
      this.tr_marca = null; 
      this.array_arreglo = [];
    },
    async actualizar_marca(id, nombre){
      this.editor_marca.id = id;
      this.editor_marca.nombre = nombre;
      try {
        let token  = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `Catalogo/EditarMarca?token=${token}`, this.editor_marca);
        this.$Toast.fire({
          type: 'info',
          title: 'Marca editada'
        });
        this.Traer_marca();
        this.Traer_detalle();
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en editar Marca!!!'
        });
      }
    },
    async actualizar_modelo(id, nombre){
      this.editor_modelo.id = id;
      this.editor_modelo.nombre = nombre;
      try {
        let token  = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `Catalogo/EditarModelo?token=${token}`, this.editor_modelo);
        this.$Toast.fire({
          type: 'info',
          title: 'Modelo editado'
        });
        this.Traer_modelo();
        this.Traer_detalle();
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en editar Modelo!!!'
        });
      }
    },
    SeleccionMarca(id, marca){
      this.modal.marca = marca;
      this.modal.marca_id = id;
    },
    SeleccionModelo(id, modelo){
      this.modal.modelo = modelo;
      this.modal.modelo_id = id;
    },
    CrearModal(){
      this.modal = {
        id_detalle: 0,
        marca_id: 0,
        marca: '',
        modelo_id: 0,
        modelo: '',
        detalle: '',
        ano: '',
        f_aire: '',
        f_aceie: '',
        f_combustible: '',
        bujia: '',
        otro: ''
      };
    },
    EditarModal(detalle){
      this.modal = {
        id_detalle: detalle.id,
        ano: detalle.ano,
        detalle: detalle.detalle,
        f_aire: detalle.filtro_aire,
        f_aceie: detalle.filtro_aceite,
        f_combustible: detalle.filtro_combustible,
        bujia: detalle.bujia,
        otro: detalle.otro
      };

      for (let marca of this.array_marca) {
        if (marca.id === detalle.id_marca) {
          this.modal.marca = marca.nombre;
          this.modal.marca_id = marca.id;
        }
      }

      for (let modelo of this.array_modelo) {
        if (modelo.id === detalle.id_modelo) {
          this.modal.modelo = modelo.nombre;
          this.modal.modelo_id = modelo.id;
        }
      }
    },
    async Agregar_modal(){
      try {
        let token  = await localStorage.getItem("user_token");
        let result = await this.$JQuery.post(this.$Base_url + `Catalogo/GE_Detalle?token=${token}`, this.modal);
        if (result == '0') {
          this.$Toast.fire({
            type: 'info',
            title: 'Detalle editado'
          });
          this.modal_catalogo = false;
        } else if (result > '0') {
          this.$Toast.fire({
            type: 'success',
            title: 'Detalle creado'
          });
          this.CrearModal();
        }

        await this.Traer_marca();
        this.Traer_modelo();
        this.Traer_detalle();

        for (let marca of this.array_marca) {
          if (marca.id == result) {
            this.modal.marca = marca.nombre;
            this.modal.marca_id = marca.id;
          }
        }
      } catch (error) {
        this.$Toast.fire({
          type: 'error',
          title: 'Falla en editar Modelo!!!'
        });
      }
    },
    async Agregar_venta(id){
      try {
        let token = await localStorage.getItem("user_token");
        await this.$JQuery.post(this.$Base_url + `Ventas/Enviar_temporal?token=${token}`, { 'id_usuario' : localStorage.getItem("user_id"), 'id_codigo' : id });
        this.$Toast.fire({
          type: 'success',
          title: 'producto agregado'
        });
        this.$socket.emit('Update_table_temporal', { 'usuario' : localStorage.getItem("user_id") });
      } catch (error) {
        console.log(error);
      }
    }
  },
  computed: {
    filtrar_marcas() {
      var buscarLowerCase = this.imput_marca.toLowerCase();
      return this.array_marca.filter((marca) => {
        return marca.nombre.toLowerCase().match(buscarLowerCase);
      });
    },
    filtrar_modelos() {
      var buscarLowerCase = this.imput_modelo.toLowerCase();
      let modelo = this.array_arreglo.length == 0 ? this.array_modelo : this.array_arreglo;
      return modelo.filter((modelo) => {
        if (this.item.marca_id == modelo.id_marca) {
          return modelo.nombre.toLowerCase().match(buscarLowerCase);
        }
        if (this.item.marca_id == 0) {
          return modelo.nombre.toLowerCase().match(buscarLowerCase);
        }
      });
    },
    filtrar_detalles() {
      var buscarLowerCase = this.imput_detalle.toLowerCase();
      return this.array_detalle.filter((detalle) => {
        if (this.item.marca_id == detalle.id_marca && this.item.modelo_id == 0) {
          return detalle.filtro_aire.toLowerCase().match(buscarLowerCase) || detalle.filtro_aceite.toLowerCase().match(buscarLowerCase) || detalle.filtro_combustible.toLowerCase().match(buscarLowerCase);
        }
        if (this.item.modelo_id == detalle.id_modelo) {
          return detalle.filtro_aire.toLowerCase().match(buscarLowerCase) || detalle.filtro_aceite.toLowerCase().match(buscarLowerCase) || detalle.filtro_combustible.toLowerCase().match(buscarLowerCase);
        }
        if (this.item.marca_id == 0 && this.item.modelo_id == 0) {
          return detalle.filtro_aire.toLowerCase().match(buscarLowerCase) || detalle.filtro_aceite.toLowerCase().match(buscarLowerCase) || detalle.filtro_combustible.toLowerCase().match(buscarLowerCase);
        }
      });
    },
    buscar_marcas() {
      var buscarLowerCase = this.modal.marca ? this.modal.marca.toLowerCase() : '';
      return this.array_marca.filter((marca) => {
        return marca.nombre.toLowerCase().match(buscarLowerCase);
      });
    },
    buscar_modelos() {
      var buscarLowerCase = this.modal.modelo ? this.modal.modelo.toLowerCase() : '';
      return this.array_modelo.filter((modelo) => {
        return modelo.nombre.toLowerCase().match(buscarLowerCase);
      });
    },
  },
  mounted: async function () {
    this.Traer_marca();
    this.Traer_modelo();
    this.Traer_detalle();
  }
}
</script>
